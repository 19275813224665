import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import {
  BasicBanner,
  Container,
  Section,
  Seo,
} from '@components'
import { LandingPageLayout } from '../../../components/site/layout/landing-page-layout'
import { OfferForm } from '../../../components/site/offer'

const imgDir = '../../../components/site/images'

const Headline = () => {
  return (
    <>
      <BasicBanner
        title="The State of Mobile App Security 2022"
        subtitle="Osterman Research Report"
      />

      <Section>
        <Container className="mb-8 container-lg">
          <h2 className="mt-12 mb-4 text-3xl text-center">
            Findings Reveal Both the Increased Importance of Mobile Apps and The
            Relative Lack of Focus on Runtime App and Data Protection
          </h2>
        </Container>
      </Section>
    </>
  )
}

const Offer = () => {
  return (
    <div>
      <p>
        Mobile apps have become key tools for businesses to serve customers,
        earn revenue, and enable remote work by employees. Over the last two
        years, mobile apps have become critical to success for the majority of
        businesses.
      </p>
      <p>
        In this report, we present the findings of a survey into the state of
        mobile app security in 2022, encompassing survey respondents from across
        the United States and the United Kingdom. The survey and white paper
        were commissioned by Approov.
      </p>
      <div className="mx-16 lg:mx-24">
        <StaticImage
          src={`${imgDir}/stored-keys.jpg`}
          alt="API Keys Stored in Mobile Apps"
          placeholder="blurred"
        />
      </div>
      <p>A 30 minute webinar on July 26 will reveal additional findings.</p>
    </div>
  )
}

const Action = () => {
  return (
    <>
      <h3 className="pl-4 pr-0 mt-4 mb-2 text-2xl text-center text-gray-700 lg:mt-0">
        Download a Full Copy of the Report and Watch a Video Summary of the
        Findings
      </h3>
      <OfferForm offer="mobappsec_2022" />
    </>
  )
}

const Details = () => {
  return (
    <>
      <h3 className="mt-0">Key Takeaways from the report:</h3>
      <ul className="mt-8">
        <li>
          <span className="font-bold">
            Three out of four respondents report that mobile apps are now
            “essential” or “absolutely core” to their success
          </span>
          : This is three times higher than two years ago.
        </li>
        <li>
          <span className="font-bold">
            Secure development practices are essential but offer only partial
            protection
          </span>
          : They do not eliminate the threat of run-time attacks against mobile
          apps and APIs.
        </li>
        <li>
          <span className="font-bold">
            Run-time attacks against APIs that render mobile apps non-functional
            prove costly to 75% of organizations
          </span>
          : Attacks include data theft via API abuse, fake account creation, and
          credit fraud, among others.
        </li>
        <li>
          <span className="font-bold">
            Organizations lack visibility into run-time threats against mobile
            apps and APIs
          </span>
          : 60% of organizations report that they do not have visibility to
          run-time threats against mobile apps and APIs.
        </li>
        <li>
          <span className="font-bold">
            Reducing threats arising due to hardcoded API keys is a priority
          </span>
          : With about half of mobile apps storing API keys as hard coded
          secrets, the use of more than 30 third-party APIs per mobile app
          creates a significant run-time threat space. 55% of respondents place
          high priority on removing the need to store API keys and other hard
          coded secrets in mobile apps.
        </li>
        <li>
          <span className="font-bold">
            Organizations prioritize accelerating time-to-market for new
            features over security
          </span>
          : One half of respondents report that for competitive reasons, their
          organizations may ship apps with known insecurities, and two fifths of
          respondents report that their organization’s security processes for
          both third-party and in house developers are weak and insufficient.{' '}
        </li>
      </ul>
    </>
  )
}

const LandingPage = ({ location }) => {
  return (
    <LandingPageLayout pathname={location.pathname}>

      <Headline />

      <Section>
        <Container className="mb-8 container-lg">
          <div className="flex flex-col justify-center lg:flex-row lg:content-center lg:items-center">
            <div className="flex items-center justify-center lg:w-6/12">
              <Offer />
            </div>

            <div className="lg:pl-8 lg:pr-0 lg:w-6/12">
              <Action />
            </div>
          </div>
        </Container>

        <Container className="mb-8 container-lg">
          <Details />
        </Container>
      </Section>
    </LandingPageLayout>
  )
}

export default LandingPage
